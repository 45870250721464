import { render, staticRenderFns } from "./QrCode.pug?vue&type=template&id=7c3bce7f&scoped=true&lang=pug&"
import script from "./QrCode.vue?vue&type=script&lang=js&"
export * from "./QrCode.vue?vue&type=script&lang=js&"
import style0 from "./QrCode.vue?vue&type=style&index=0&id=7c3bce7f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c3bce7f",
  null
  
)

export default component.exports